// /* eslint no-console: 0 */
// // Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// // <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// // to the head of your layout file,
// // like app/views/layouts/application.html.erb.
// // All it does is render <div>Hello Vue</div> at the bottom of the page.

// // import Vue from 'vue'
// // import App from '../app.vue'

// // document.addEventListener('DOMContentLoaded', () => {
// //   const app = new Vue({
// //     render: h => h(App)
// //   }).$mount()
// //   document.body.appendChild(app.$el)

// //   console.log(app)
// // })


// // The above code uses Vue without the compiler, which means you cannot
// // use Vue to target elements in your existing html templates. You would
// // need to always use single file components.
// // To be able to target elements in your existing html/erb templates,
// // comment out the above code and uncomment the below
// // Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// // Then add this markup to your html template:
// //
// // <div id='hello'>
// //   {{message}}
// //   <app></app>
// // </div>


// // import Vue from 'vue/dist/vue.esm'
// // import App from '../app.vue'
// //
// // document.addEventListener('DOMContentLoaded', () => {
// //   const app = new Vue({
// //     el: '#hello',
// //     data: {
// //       message: "Can you say hello?"
// //     },
// //     components: { App }
// //   })
// // })
// //
// //
// //
// // If the project is using turbolinks, install 'vue-turbolinks':
// //
// // yarn add vue-turbolinks
// //
// // Then uncomment the code block below:
// //
// import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.js'
import App from '../call_booking.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';


// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';


Vue.use(VueSweetalert2);
// Vue.use(TurbolinksAdapter)
Vue.use(VueAxios, axios)
Vue.component("loading", Loading);
Vue.config.productionTip = false

// document.addEventListener('turbolinks:load', () => {
document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    el: '#call_booking',
    data: () => {
      return {
        phone: null,
        name: null,
        text: null,
        showModal: false
      }
    },
    components: { App },
    methods: {
      sendBooking() {
        console.log(this.phone, this.name, this.text)
      }
    }
  })
})
// Turbolinks.start()